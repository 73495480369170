import React, { useState } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import { message } from "antd";
import clsx from "clsx";
import { last } from "lodash";

import timeBlockService from "~/utils/api/v1/timeblockService";
import { unassignTimeBlocks } from "~/utils/api/v2/timeblocks";
import { TimeBlock } from "~/utils/interfaces/Timeblock";

type BlockProps = {
  id: string;
  data: TimeBlock[];
  onDelete?: () => void;
  isBlocked?: boolean;
};

function Block({ id: nurseId, data, onDelete, isBlocked }: BlockProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const blockStart = data[0].begin_hour ?? "";
  const blockEnd = last(data)?.end_hour ?? "";

  const deleteTimeblocks = async () => {
    setLoading(true);
    try {
      const ids = data.map((item) => item.id);
      if (isBlocked) {
        await Promise.all(
          ids.map(async (id: string) => {
            return timeBlockService.unblockTimeBlock(id, { nurse_id: nurseId });
          }),
        );
        message.success("Bloqueo eliminado correctamente");
        onDelete?.();
      } else {
        await unassignTimeBlocks({ nurseId: nurseId, timeblocksIds: ids });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div
      className={clsx("flex flex-row items-center p-2 rounded-md bg-gray-100 flex-nowrap", loading && "bg-gray-300")}
    >
      {blockStart} - {blockEnd}
      <span
        onClick={deleteTimeblocks}
        className="cursor-pointer ml-4"
      >
        <CancelIcon />
      </span>
    </div>
  );
}

export default Block;
