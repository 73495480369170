import React, { useState } from "react";

import clsx from "clsx";

import AddBlockedTimeblock from "~/components/Timeblocks/AddBlockedTimeblock";
import DisplayEditableBlockedTimeBlock from "~/components/Timeblocks/DisplayEditableBlockedTimeBlock";
import { TimeBlock, GroupedTimeBlocks } from "~/utils/interfaces/Timeblock";

type BlocksPerDayProps = {
  nurseId: string;
  allTimeblocks: TimeBlock[];
  refetchNurseTimeblocks: () => void;
  blockedTimeBlocks: GroupedTimeBlocks;
};

function BlocksPerDay({ nurseId, allTimeblocks, refetchNurseTimeblocks, blockedTimeBlocks }: BlocksPerDayProps) {
  const [showAddBlocked, setShowAddBlocked] = useState(false);

  return (
    <>
      {!showAddBlocked && (
        <button
          className={clsx(
            "w-fit px-3.5 py-2.5 mb-4",
            "text-center text-examedi-white-pure",
            "bg-examedi-blue-strong hover:bg-examedi-blue-strong-50",
            "rounded-md",
          )}
          onClick={() => setShowAddBlocked(true)}
        >
          Agregar bloqueo
        </button>
      )}
      {showAddBlocked && (
        <AddBlockedTimeblock
          id={nurseId}
          onCancel={() => {
            setShowAddBlocked(false);
          }}
          timeblocks={allTimeblocks}
          refetchNurseTimeblocks={refetchNurseTimeblocks}
        />
      )}
      <DisplayEditableBlockedTimeBlock
        id={nurseId}
        timeblocks={blockedTimeBlocks}
        deleteBlocks={refetchNurseTimeblocks}
      />
    </>
  );
}

export default BlocksPerDay;
