import { BlockedTimeBlock, GroupedTimeBlocks, SortedTimeBlocks, TimeBlock } from "~/utils/interfaces/Timeblock";

export const lastElem = (array: Array<any>): any => {
  if (!Array.isArray(array)) {
    return false;
  }
  const len = array.length;
  return array[len - 1];
};

export const groupTimeBlocksByDay = (timeblocks: TimeBlock[]): SortedTimeBlocks => {
  const sorted = (timeblocks as any).reduce((reducer: any, item: any) => {
    const day = item.day || item.nurse_timeblock?.timeblock?.day;
    if (!reducer.hasOwnProperty(day)) {
      reducer[day] = [];
    }
    reducer[day].push(item);
    return reducer;
  }, {});
  return sorted;
};

export const groupAdjacentTimeBlocks = (timeblocks: SortedTimeBlocks): GroupedTimeBlocks => {
  const toReturn: any = {};
  for (const [key, value] of Object.entries(timeblocks)) {
    // @ts-expect-error
    toReturn[key] = value.reduce((reducer: Array<Array<TimeBlock>>, currentValue: TimeBlock) => {
      // @ts-expect-error
      const lastTimeBlockFinishHour = lastElem(lastElem(reducer)).end_hour;
      // @ts-expect-error
      const actualTimeBlockBeginHour = currentValue.begin_hour;
      if (lastTimeBlockFinishHour === actualTimeBlockBeginHour) {
        lastElem(reducer).push(currentValue);
        return reducer;
      }
      reducer.push([currentValue]);
      return reducer;
    }, []);
  }
  return toReturn;
};

/* Compose both functions in one */
export const groupTimeBlocks = (timeblocks: TimeBlock[]): GroupedTimeBlocks => {
  return groupAdjacentTimeBlocks(groupTimeBlocksByDay(timeblocks));
};

export const groupBlockedTimeBlocksByDate = (timeblocks: BlockedTimeBlock[]) => {
  /* Groups the blocked timeblocks by it's date. */
  return timeblocks.reduce(
    (reducer: Record<string, TimeBlock[]>, currentValue: BlockedTimeBlock): Record<string, TimeBlock[]> => {
      if (!reducer.hasOwnProperty(currentValue.date)) {
        reducer[currentValue.date] = [];
      }
      // switch the timeblock id for the blockedtimeblock id
      let tb;
      if (currentValue.nurse_timeblock) {
        tb = currentValue.nurse_timeblock.timeblock;
      } else {
        tb = currentValue.lab_timeblock.timeblock;
      }
      tb.id = currentValue.id;
      reducer[currentValue.date].push(tb);
      return reducer;
    },
    {},
  );
};

export const groupBlockedTimeBlocks = (timeblocks: BlockedTimeBlock[]) => {
  return groupAdjacentTimeBlocks(groupBlockedTimeBlocksByDate(timeblocks));
};
