import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import Tabs from "~/components/Common/Tabs";
import LoadingError from "~/components/Loaders/LoadingError";
import BlocksPerDay from "~/components/Nurses/NurseBlocks/BlocksPerDay";
import BlocksPerItem from "~/components/Nurses/NurseBlocks/BlocksPerItem";
import NewDisplayTimeblock from "~/components/Timeblocks/NewDisplayTimeblock";
import { TABS, PossibleTab } from "~/constants/blocksNurseTabs";
import { fetchNurseProfile } from "~/utils/api/v1/nurseService";
import sectorsAPI from "~/utils/api/v2/places";
import { getAllCountryTimeblocks, getNurseTimeblocks } from "~/utils/api/v2/timeblocks";
import { Locale } from "~/utils/interfaces/Locale";
import { GroupedTimeBlocks } from "~/utils/interfaces/Timeblock";
import { groupBlockedTimeBlocks } from "~/utils/timeblocks/group";

function NurseBlocks({ nurseId }: { nurseId: string }) {
  const [error, setError] = useState<any>({});
  const [blockedTimeBlocks, setBlockedTimeBlocks] = useState<GroupedTimeBlocks>({});
  const [currentTab, setCurrentTab] = useState<PossibleTab>(TABS[0]);

  const {
    data: nurseData,
    isLoading: isNurseDataLoading,
    error: nurseDataError,
  } = useQuery({
    queryKey: ["nurseData", nurseId],
    queryFn: async () => {
      const response = await fetchNurseProfile(nurseId);
      return response.data;
    },
  });

  const {
    data: allTimeblocksData = [],
    isLoading: isTimeblocksLoading,
    error: timeblocksError,
  } = useQuery({
    queryKey: ["allTimeblocks"],
    queryFn: async () => getAllCountryTimeblocks(nurseData?.country as Locale),
    enabled: !!nurseData?.country,
  });

  const {
    data: nurseTimeblocksData = { blocked: [], timeblocks: [] },
    isLoading: isNurseTimeblocksLoading,
    error: nurseTimeblocksError,
    refetch: refetchNurseTimeblocks,
  } = useQuery({
    queryKey: ["nurseTimeblocks", nurseId],
    queryFn: async () => {
      const response = await getNurseTimeblocks(nurseId);
      return response.data;
    },
  });

  const {
    data: sectorsData = [],
    isLoading: isSectorsLoading,
    error: sectorsError,
  } = useQuery({
    queryKey: ["sectors", nurseId],
    queryFn: async () => sectorsAPI.fetchNurseSectors(nurseId),
  });

  useEffect(() => {
    if (nurseDataError) {
      setError(nurseDataError);
    } else if (nurseTimeblocksError) {
      setError(nurseTimeblocksError);
    } else if (timeblocksError) {
      setError(timeblocksError);
    } else if (sectorsError) {
      setError(sectorsError);
    }
  }, [nurseDataError, nurseTimeblocksError, timeblocksError, sectorsError]);

  useEffect(() => {
    if (nurseTimeblocksData) {
      setBlockedTimeBlocks(groupBlockedTimeBlocks(nurseTimeblocksData.blocked));
    }
  }, [nurseTimeblocksData.blocked.length]);

  const isLoading = isNurseDataLoading || isNurseTimeblocksLoading || isTimeblocksLoading || isSectorsLoading;
  return (
    <>
      {!isLoading && <h1 className="text-4xl font-light">{`${nurseData?.names} ${nurseData?.last_names}`}</h1>}
      <div className="flex flex-col bg-white p-6 mt-4">
        <LoadingError
          loading={isLoading}
          error={error}
        />
        {!isLoading && (
          <>
            <h1 className="text-4xl font-light">Horarios actuales</h1>
            <NewDisplayTimeblock
              nurseId={nurseId}
              allTimeblocks={allTimeblocksData}
              activeTimeblocks={nurseTimeblocksData.timeblocks}
              countSectors={sectorsData.length}
            />

            <h1 className="text-4xl font-light mt-8">Horarios Bloqueados</h1>
            <Tabs
              elements={TABS}
              onSelectTab={(value: string) => setCurrentTab(value as PossibleTab)}
            >
              {currentTab === "Por día" && (
                <BlocksPerDay
                  nurseId={nurseId}
                  allTimeblocks={allTimeblocksData}
                  refetchNurseTimeblocks={refetchNurseTimeblocks}
                  blockedTimeBlocks={blockedTimeBlocks}
                />
              )}
              {currentTab === "Por item" && (
                <BlocksPerItem
                  nurseId={nurseId}
                  blockedTimeBlocks={blockedTimeBlocks}
                  refetchNurseTimeblocks={refetchNurseTimeblocks}
                />
              )}
            </Tabs>
          </>
        )}
      </div>
    </>
  );
}

export default NurseBlocks;
