import React from "react";

import Block from "~/components/Timeblocks/Block";
import { daysToSpanish, monthNames } from "~/utils/dates/names";
import { TimeBlock } from "~/utils/interfaces/Timeblock";

type DisplayEditableBlockedTimeBlockProps = {
  id: string;
  timeblocks: Record<string, TimeBlock[][]>;
  deleteBlocks: () => void;
};

const DisplayEditableBlockedTimeBlock = ({
  id,
  timeblocks,
  deleteBlocks,
}: DisplayEditableBlockedTimeBlockProps): JSX.Element => {
  const displayDay = (date: string, dayData: TimeBlock[][]): JSX.Element => {
    const [year, month, day] = date.split("-", 3);
    const dayName = daysToSpanish[dayData[0][0].day];
    const dateName = `${dayName} ${day} de ${monthNames[parseInt(month) - 1]} de ${year}`;

    const blocks = dayData.map((block): JSX.Element => {
      return (
        <Block
          id={id}
          data={block}
          key={block[0].id}
          onDelete={deleteBlocks}
          isBlocked
        />
      );
    });

    return (
      <div
        className="border border-gray-200 p-4"
        key={date}
      >
        <h4>{dateName}</h4>
        <div className="flex flex-row flex-wrap">{blocks}</div>
      </div>
    );
  };

  const displayAllBlocks = (): JSX.Element[] => {
    const daysArray = [];

    for (const [key, value] of Object.entries(timeblocks)) {
      daysArray.push(displayDay(key, value));
    }

    return daysArray;
  };

  return <>{displayAllBlocks()}</>;
};

export default DisplayEditableBlockedTimeBlock;
