import React from "react";

import dayjs from "dayjs";

import { BlockedNurseItemDetailed } from "~/utils/interfaces/Items";

type DisplayBlockedItemProps = {
  blockedItems: BlockedNurseItemDetailed[];
};

function DisplayBlockedItem({ blockedItems }: DisplayBlockedItemProps) {
  return (
    <div className="flex flex-col gap-2">
      {blockedItems.map((blockedItem) => (
        <div
          key={blockedItem.id}
          className="flex flex-col bg-gray-100 p-2 border border-gray-300 rounded-md"
        >
          <div className="text-lg font-bold">{blockedItem.item.name}</div>
          <div className="text-sm text-gray-500">
            Desde: {dayjs(blockedItem.begin_date).format("DD/MM/YYYY - HH:mm")}
          </div>
          <div className="text-sm text-gray-500">Hasta: {dayjs(blockedItem.end_date).format("DD/MM/YYYY - HH:mm")}</div>
        </div>
      ))}
    </div>
  );
}

export default DisplayBlockedItem;
