import React from "react";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import NurseBlocks from "~/components/Nurses/NurseBlocks";

type BlockScheduleProps = {
  id: string;
};

const BlockSchedule = ({ id: nurseId }: BlockScheduleProps): JSX.Element => {
  return (
    <PrivateRoute>
      <NurseBlocks nurseId={nurseId} />
    </PrivateRoute>
  );
};

export default BlockSchedule;
