import React from "react";

import { useQuery } from "@tanstack/react-query";

import DisplayBlockedItem from "~/components/Nurses/NurseBlocks/DisplayBlockedItem";
import { getBlockedItems } from "~/utils/api/v2/nurse";

type BlocksPerItemProps = {
  nurseId: string;
  blockedTimeBlocks: any;
  refetchNurseTimeblocks: () => void;
};

function BlocksPerItem({ nurseId }: BlocksPerItemProps) {
  const { data: blockedItems = [] } = useQuery({
    queryKey: ["blockedItems", nurseId],
    queryFn: async () => {
      const res = await getBlockedItems(nurseId);
      return res.data;
    },
  });

  return <DisplayBlockedItem blockedItems={blockedItems} />;
}

export default BlocksPerItem;
